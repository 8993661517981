import React from 'react';
import { connect } from 'react-redux';
import { AndraContainer } from "../Andra";
import axios from "axios";
import {config} from "../../app/config";
import {ListenButton} from './main/ListenButton';
import '../../styles/screens/main.scss';
import Bloobs from "../Bloobs";
import TranslationService from "../../app/services/TranslationService";
import { say, alert } from "../../actions";
import {HelloMessage} from "../HelloMessage";


export class MainClass extends React.Component {
    /**
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            info: [],
            queryText: '',
            query: '',
            thinking: false,
            answer: '',
            instruction: null,
            data: null,
            html: null
        }
    }

    componentDidMount() {
        let that = this;
        axios.get(config.apiAddress + '/information')
            .then(function (response) {
                that.setState({info: response.data});
            }).catch(function(error) {
            });
    }

    getStyle() {
        return {
            display: (this.props.user !== null && this.props.user.name !== null) && (this.props.view === 'main' || this.props.view === null) ? 'block' : 'none'
        }
    }

    catchTextFromMic = (text) => {
        this.setState({query: text, thinking: true});
        this.sendQuery(this.state.query);
    }

    handleQueryTextChange = (event) => {
        this.setState({queryText: event.target.value});
    };

    handleKeyDown = (event) => {
        if (event.key === 'Enter' && this.state.queryText !== '') {
            event.target.blur();
            this.setState({queryText: '', query: this.state.queryText, thinking: true});
            this.sendQuery(this.state.queryText);
        }
    };

    sendQuery = (query) => {
        let that = this;
        this.setState({answer: '', html: null});

        axios.post(config.apiAddress + '/query?lang=' + TranslationService.getLanguage(this.props.user), {query: query})
            .then(function (response) {
                that.setState({
                    thinking: false,
                    answer: response.data.answer,
                    instruction: response.data.instruction ?? null,
                    data: response.data.data ?? null,
                    html: response.data.html ?? null
                });
                that.props.say(response.data.answer);
            }).catch(function(error) {
                that.setState({thinking: false, answer: TranslationService.trans(that.props.language, 'main.actions.query_failed')});
                that.props.say(TranslationService.trans(that.props.language, 'main.actions.query_failed'));
            });
    }

    renderInformation = () => {
        let rows = [];

        //normal
        this.state.info.forEach(function(info) {
            let className = 'imd-bg-80-blue imd-text-gray';
            if (info.type === 'success') {
                className = 'imd-bg-80-green imd-text-120-green';
            } else if (info.type === 'danger') {
                className = 'imd-bg-80-red imd-text-120-red';
            } else if (info.type === 'warning') {
                className = 'imd-bg-80-yellow imd-text-gray';
            }

            rows.push(<div className={'imd-notification ' + className}>
                <span dangerouslySetInnerHTML={{__html: info.content}} />
            </div>);
        });

        return rows;
    }

    render() {
        return (
            <div className="screen" style={this.getStyle()} id="screen-main">
                {this.renderInformation()}

                <div className='imd-hide-xs imd-hide-sm'>
                    <AndraContainer scale={5} style={{margin: '0 auto 50px auto'}} />
                </div>
                <div className='imd-hide-md imd-hide-lg imd-hide-xl'>
                    <AndraContainer scale={3} style={{margin: '0 auto 50px auto'}} />
                </div>

                {
                    this.state.query !== '' ?
                    <div className="imd-text-main query">{this.state.query}</div> :
                    <span className="imd-text-main hello">
                        <HelloMessage />
                    </span>
                }

                {
                    this.state.thinking ?
                    <Bloobs /> :
                    ''
                }

                {
                    this.state.answer !== '' ?
                        <div className="imd-text-contrast answer">{this.state.answer}</div> :
                        ''
                }

                {
                    this.state.instruction === 'img' ?
                        <div className={"imd-table-responsive"} style={{textAlign: 'center'}}>
                            <img
                                src={config.apiAddress + '/image/' + this.state.data + '.png'}
                                style={{maxWidth: '90vw', maxHeight: '250px'}}
                                alt={TranslationService.trans(this.props.language, 'main.image_answer', this.props.user)}
                            />
                        </div>:
                        ''
                }

                {
                    this.state.html !== null ?
                        <div dangerouslySetInnerHTML={{ __html: this.state.html }} /> :
                        ''
                }

                <div style={{position: 'fixed', bottom: 0, padding: '13px', width: 'calc(100% - 26px)'}} className="query-block">
                    <div className="imd-form-group">
                        <input
                            className="imd-text-main"
                            placeholder={TranslationService.trans(this.props.language, 'main.how_can_i_help_you')}
                            style={{background: 'none', order: 0}}
                            value={this.state.queryText}
                            onChange={this.handleQueryTextChange}
                            onKeyDown={this.handleKeyDown}
                        />
                        <ListenButton
                            catchTextFromMic={this.catchTextFromMic}
                            startListeningCallback={() => this.setState({answer: ''})}
                            alert={this.props.alert}
                            lang={this.props.language}
                        />
                        <div className="imd-decorator imd-bg-main" />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        language: TranslationService.getLanguage(state.user),
        view: state.view
    }
};

export const MainScreen = connect(mapStateToProps, { say, alert })(MainClass);
